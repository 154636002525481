.daily-report-middle-part{
  margin-top: 100px;
  //width: 70%;
  height: auto;
  /* display: flex;
  flex-direction: column; */
  gap: 15px;
}
.daily-report-middle-part-1st-row {
display: flex;
flex-direction: column;
align-items: center; /* Centers content horizontally */
margin: 0 auto; /* Ensures the content stays centered in the horizontal middle */
width: 100%; /* Takes the full width */
text-align: center;
}

.daily-report-middle-part-1st-row-title {
color: white;
font-size: 2.5vw; /* Responsive font size based on viewport width */
margin-bottom: 20px;
}

.daily-report-middle-part-1st-row-date {
display: flex;
flex-direction: row;
gap: 10px;
align-items: center;
justify-content: center; /* Ensures the date elements are horizontally centered */
}

.daily-report-middle-part-1st-row-date button {
background-color: #00FFC2;
color: black;
padding: 10px 15px;
border: none;
border-radius: 5px;
cursor: pointer;
}

.daily-report-middle-part-1st-row-datepicker {
text-align: center;
padding: 10px;
border-radius: 5px;
font-size: 1rem;
}
.daily-report-middle-part-2nd-row{
  display: flex;
  flex-direction: column;
  /* margin-left: 25%; */
  gap: 10px;
}
.daily-report-middle-part-2nd-row-row {
  color: white;
  //gap: 5px;
}
.daily-report-middle-part-2nd-row-row-meet {
  color: white;
  //gap: 5px;
}
.daily-report-middle-part-2nd-row-row-input{
  color: black;
  width: 30%;
}
.daily-report-middle-part-2nd-row-row-meet-input{
  color: black;
  width: 30%;
}
.daily-report-middle-part-2nd-row-row-textarea{
color: black;
width: 30%;
}

.daily-report-middle-part-2nd-row-row-comment{
  font-size: 30px;
  text-align: center;
  color: white;
}
/* .daily-report-middle-part-2nd-row-row input,
  .daily-report-middle-part-2nd-row-row textarea {
    
  } */
.daily-report-middle-part-2nd-row-row hr{
  color: white;
  //width: 920px;
  align-self: center;
}
.daily-report-middle-part-2nd-row div21{
  color: white;   
  margin-right: 20px; 
}
.daily-report-middle-part-2nd-row div21 input{
  width: 10%;   
  margin-left: 5px;
  color: black;
}
.daily-report-save{
  margin-left: 45%;
  background-color: #00FFC2;
  width: 10%;
  text-align: center;
  padding: 10px;
  font-size: 20px;
  border-radius: 30px;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 30px;
  cursor: pointer;
}


@media (max-width: 1300px) {

  .daily-report-middle-part-1st-row-title {
    font-size: 1.25rem;
    text-align: center;
  }

  .daily-report-middle-part-2nd-row-row-input{
    width: 60%;
  }
  .daily-report-middle-part-2nd-row-row-meet-input{
    width: 60%;
  }

  .daily-report-save {
    width: 20%;
    padding: 15px;
    margin-left: 40%;

  }
}

@media (max-width: 900px) {

  .daily-report-middle-part-2nd-row-row-input{
    width: 60%;
  }

  .daily-report-save {
    width: 20%;
  }
}

@media screen and (max-width: 450px){
  .daily-report-middle-part{
    justify-self: center;
  }   

  
  .daily-report-middle-part-2nd-row-row{
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  .daily-report-middle-part-2nd-row-row-meet{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
  }
  .daily-report-middle-part-2nd-row-row-textarea{
    align-content: center;
    width: 80%;
    margin-left: 10%;
  }
  .daily-report-save{
      margin-left: 30%;
      width: 30%;
  }
  .daily-report-middle-part-2nd-row-row-input{
    width: 80%;
  }
}

@media screen and (max-width: 360px){

.daily-report-middle-part-2nd-row-row-meet-input{
  width: 40%;
}
.daily-report-save{
  width: 30%;
}

}