.footer-container{
    position: relative;
    //margin-top: auto;
    /* display: flex;
    flex-direction: row; */
    //position: fixed;
    //left: 0;
    //margin-top: 80px;
    //bottom: 0;
    //width: 100%;
    //background-color: #00725D;
}
.footer_logo{
    width: 60%;
    height: 10%;

}
.vertical_dotted_line
{
    border-left: 2px dotted white;
    height: 200px;
} 
.footer-mail{
    font-size: 15px;
}

@media screen and (max-width: 992px){
    .footer-mail{
        font-size: 10px;
    }
    .vertical_dotted_line
    {
        height: 300px;
    } 
    .footer_logo{
        width: 80%;    
    }
    
}
@media screen and (max-width: 760px){
    .vertical_dotted_line
    {
        /* border-bottom: 2px dotted white;
        width: 200px;
        border-left: none; */
        display: none;
    } 
    /* .footer_logo{
        width: 80%;    
    } */
}