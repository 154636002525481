.theme-changer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* background: linear-gradient(135deg, #72EDF2 10%, #6ae551 100%); */
  color: #050000;
  text-align: center;
}
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

.password-reset-button {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border: none;
  border-radius: 5px;
  background: #5151E5;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease;
}
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    align-items: center;
    justify-items: center;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    width: 80px;

  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .icon {
    position: absolute;
    font-size: 20px;
    z-index: 0;
  }
  
  .sun-icon {
    left: 10px;
    top: 5px;
  }
  
  .moon-icon {
    right: 10px;
    top: 5px;
  }
  
