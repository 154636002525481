/* Container */
.feedback-container {
    max-width: 800px;
    margin: 100px auto; /* Add 100px margin on top and bottom */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading */
  .feedback-heading {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
    color: white;
  }
  
  /* Paragraph */
  .feedback-paragraph {
    font-size: 1rem;
    color: white;
    line-height: 1.6;
    margin-bottom: 30px;
    text-align: center;
  }
  
  /* Feedback Form */
  .feedback-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-label {
    font-size: 1rem;
    color: white;
    margin-bottom: 5px;
  }
  
  .form-input, .form-textarea {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .form-input {
    width: 100%;
  }
  
  .form-textarea {
    width: 100%;
  }
  
  .form-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .form-button:hover {
    background-color: #0056b3;
  }
  