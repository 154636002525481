/* Container */
.terms-container {
    max-width: 800px;
    margin: 100px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Section */
  .terms-section {
    margin-bottom: 30px;
  }
  
  /* Headings */
  .terms-heading {
    font-size: 2rem;
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .terms-subheading {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 15px;
  }
  
  /* Paragraphs */
  .terms-paragraph {
    font-size: 1rem;
    color: white;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  /* Links */
  .terms-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-link:hover {
    text-decoration: underline;
  }
  