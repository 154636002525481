.mainContainer {
    display: flex;
    flex-direction: column;
    //max-width: 100%;
    width: 100%;
    border-color: white;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    border-radius: 5px;
    //margin: 1em;
    margin-top: 10px;
}

.childrenContainer {
    padding: 0.5em;
}

.header {
    display: flex;
    flex-direction: row;
    width: 100% !important;
}

.headerBorderBefore {
    border-top: 1px solid white;
    width: 1em;
    border-top-left-radius: 5px;
}

.headerTitle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.25em;
    width: fit-content;
    height: 2em;
    margin: -1em 0.5em 0em 0.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1em;
    font-weight: 600;
    color: white;
}

.headerBorderAfter {
    border-top: 1px solid white;
    width: 1em;
    flex-grow: 2;
    border-top-right-radius: 5px;
}

/* @media screen and (max-width: 360px){
    .mainContainer {
        width: 115%;
    }
} */