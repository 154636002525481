/* Container */
.faq-container {
    max-width: 800px;
    margin: 100px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading */
  .faq-heading {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 30px;
    color: white;
  }
  
  /* FAQ Item */
  .faq-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
  }
  
  /* FAQ Question */
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .faq-question-title {
    font-size: 1.25rem;
    color: white;
  }
  
  .faq-toggle-icon {
    font-size: 1.5rem;
    color: #007bff;
  }
  
  /* FAQ Answer */
  .faq-answer {
    margin-top: 10px;
    padding-left: 20px;
  }
  
  .faq-answer p {
    font-size: 1rem;
    color: white;
    line-height: 1.6;
  }
  