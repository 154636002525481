/* Container */
.privacy-container {
    max-width: 800px;
    margin: 100px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Section */
  .privacy-section {
    margin-bottom: 30px;
  }
  
  /* Headings */
  .privacy-heading {
    font-size: 2.2rem;
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-subheading {
    font-size: 1.6rem;
    color: white;
    margin-bottom: 15px;
  }
  
  /* Paragraphs */
  .privacy-paragraph {
    font-size: 1rem;
    color: white;
    line-height: 1.7;
    margin-bottom: 15px;
  }
  
  /* Links */
  .privacy-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-link:hover {
    text-decoration: underline;
  }
  