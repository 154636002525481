/* src/components/PasswordResetRequest.css */

.password-reset-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #12B48D;
    color: #220707;
    text-align: center;
    
}
.go-back-button{
    margin-top: 10px;
    background-color: red;
}

.password-reset-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    border-radius: 10px;
    backdrop-filter: blur(10px);
}

.password-reset-input {
    padding: 0.5rem;
    margin: 0.5rem 0;
    border: none;
    border-radius: 5px;
    width: 250px;
    color:black
}

.password-reset-button {
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    border: none;
    border-radius: 5px;
    background: #5151E5;
    color: #fff;
    cursor: pointer;
    transition: background 0.3s ease;
}

.password-reset-button:hover {
    background: #72EDF2;
}

.password-reset-message {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: bold;
}

.password-reset-input-wrapper {
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.spinner {
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 14px;
    height: 14px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
