
.profile-holder{
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    margin-bottom: 50px;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    border-color: white;
    width: 80%;
    align-self: center;
    justify-self: center;
    align-content: center;
}
/* .profile-main{
    border-width: 2px;
    border-color: white;
    height: 600px;
    width: 700px;
    align-content: center;
    justify-items: center;

} */
.profile-info{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 80%;
    align-items: center;
    margin-top: 20px;
    
}
.profile-info Button{
    background-color: rgb(9, 177, 9);
    cursor: pointer;
    margin-top: 10%;
    margin-bottom: 5px;
    
}

.profile-info-2{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.profile-info-2 input{
    border-width: 1px;
    padding: 10px;
    width: 500px;
    color: black;
}

.profile-info-2 select{
    border-width: 1px;
    padding: 10px;
    width: 500px;
    color: black;
}
.profile-info-2 label{
    display: flex;
    flex-direction: column;
    color: white;
}


@media screen and (max-width: 450px){
    .profile-main{
        width: 90%;
    }
    
    .profile-info-1 input{
        align-self: center;
        width: 140px;
    }
    .profile-info-2 input{
        width: 280px;
    }
    .profile-info-2 select{
        width: 280px;
    }
    
}
@media screen and (max-width: 350px){
    .profile-info-1 input{
        width: 120px;
    }
    .profile-info-2 input{
        width: 240px;
    }
    .profile-info-2 select{
        width: 240px;
    }
    
}