.home-middle{
    //position: relative;
    display: flex;
    flex-direction: column;
    //margin-top: 100px;
    margin-left: 20%;
    margin-bottom: 20px;
    width: 60%;
    //height: 700px;
    align-items: center;
    //border-width: 2px;
}
.login-not-user-top-part{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}
.login-not-user-top-part img{
    width: 50%;

}
.login-not-user-top-part-title{
    color: white;
    font-size: 50px;
}
.home-middle-quranic-verse{
    margin-left: 20%;
}
.login_page_ayat_meaning{
    width: 70%;
}
.login_page_ayat{
    width: 70%;
}
.login-form{
    //position: relative;
    width: 812px;
    height: 509px;
    //border-radius: 17px;
    margin-top: 20px;
    //margin-left: 30%;
    //background-color: white;
    display: flex;
    flex-direction: row;
}
.login-form-left-part{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    width: 820px;
    height: 509px;
    background-color: white;
    border-bottom-left-radius: 17px;
    border-top-left-radius: 17px;
}
.login-form-left-part form{
    display: flex;
    flex-direction: column;
    width: 400px;
    //margin-left: 20%;
    gap: 5px;
    align-items: center;
}
/* .login-form-left-part form label{
    display: flex;
    flex-direction: column;
} */
.login-form-left-part-form-input{
    outline: 0;
    border-width: 0 0 2px;
    border-color: black;
    width: 80%;
}
.login-form-left-part form input:focus{
    //border-color: green;
    outline: none;
    border-width: 0 0 2px;
}
/* .login-form-left-part form submit{
    cursor: pointer;
    background-color: #12B48D;
    padding: 10px;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
    color: white;
} */
.login-form-left-part-under-form{
    display: flex;
    flex-direction: row;
    //margin-top: 10px;
    //gap: 70px;
}

.signup-form-left-part-button{
    cursor: pointer;
    background-color: #12B48D;
    padding: 2px;
    width: 191px;
    align-items: center;
    height: 37px;
    margin-top: 55px;
    font-size: 20px;
    font-weight: 500;
    color: white;
    text-align: center;
    //vertical-align: middle;
}
.login-form-left-part-bottom-part{
    margin-top: 40px;
}
.login-form-left-part-bottom-part span{
    color: #12B48D;
    cursor: pointer;
}

.login-form-right-part{
    display: flex;
    //align-items: flex-end;
    width: 310px;
    height: 509px;
    height: auto;
    //border-radius: 17px;
    background-color: #24BA84;
    border-bottom-right-radius: 17px;
    border-top-right-radius: 17px;
    //border-width: 2px;
    //border-color: black;
    //z-index: 99;
}
.login-form-right-part img{
    width: 192px;
    height: 190px;
    align-self: center;
    margin-left: 5%;
}
.home-sign-in-with-google{
}
.home-show-password{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}



@media screen and (max-width: 1200px){
    
}
@media screen and (max-width: 1024px){
    .login-not-user-top-part{
        gap: 10px;
    }
    .login-not-user-top-part img{
        width: 40%;
    
    }
    .login-not-user-top-part-title{
        color: white;
        font-size: 40px;
    }
    .login-form{
        width: 612px;
        height: 409px;
    }
    .login-form-left-part{
        //padding-top: 70px;
        width: 620px;
        height: 409px;
    }
    .login-form-left-part form{
        width: 300px;
        //margin-left: 20%;
    }
    .login-form-left-part-under-form{
        gap: 25px;
        font-size: 15px;
    }

    /* //-------------------------Profile------------------------- */
    .profile-holder{
        display: flex;
        //flex-direction: column;
    }
    .profile-main{
        left: 5%;
        height: 500px;
        width: 500px;
    
    }
    .profile-info{
        margin-left: 5%;
        width: 30%;
    }
    .profile-info-1 input{
        width: 150px;
    }
    .profile-info-2 input{
        width: 300px;
    }
    /* .profile-user-photo{
        margin-top: 50px;
    }
    .profile-name{
        margin-left: 10px;
    }
    
    
     */
    /* //-------------------------Profile------------------------- */


}
@media screen and (max-width: 868px){
    .login-form{
        width: 512px;
        height: 450px;
        margin-bottom: 100px;
    }
    .login-form-left-part{
        //padding-top: 70px;
        width: 420px;
        height: 450px;
        //margin-bottom: 150px;
    }
    .login-form-left-part form{
        width: 200px;
        //margin-left: 20%;
    }
    .login-form-left-part-under-form{
        gap: 20px;
    }
    .login-form-right-part img{
        width: 152px;
        height: 150px;
    }
    .login-form-left-part submit{
        width: 101px;
    }

    /* //-------------------------Profile------------------------- */
    .profile-holder{
        display: flex;
    }
    .profile-main{
        left: 1%;
        height: 500px;
        width: 450px;
    
    }
    .profile-user-photo{
        margin-top: 50px;
    }
    .profile-name{
        margin-left: 10px;
    }
    .profile-info{
        margin-left: 10%;
        width: 50%;
    }
    .profile-info-1 input{
        width: 160px;
    }
    .profile-info-2 input{
        width: 320px;
    }
    /* //-------------------------Profile------------------------- */

}
@media screen and (max-width: 450px){
    .login-not-user-top-part-title{
        font-size: 30px;
    }
    .login_page_ayat_meaning{
        width: 90%;
    }
    .login_page_ayat{
        width: 90%;
    }
    .login-form{
        //flex-direction: column;
        width: 280px;
        height: 350px;
    }
    .login-form-left-part{
        //padding-top: 70px;
        width: 290px;
        height: 450px;
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
    }
    .login-form-left-part form{
        width: 190px;
        //margin-left: 20%;
    }
    .login-form-left-part-under-form{
        gap: 10px;
    }
    .login-form-right-part{
        display: none;
    }
    .signup-form-left-part-button{
        width: 101px;
    }

}