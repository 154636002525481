.syllabus-container {
  position: relative;
  padding-bottom: 100px;
  color: white;
}

.book-list-container {
  position: relative;
  margin-top: 20px;
  /* background-color: #37e02b; */
  padding: 15px;
  border-radius: 10px;
}

.book-list-header, .book-list-footer {
  display: flex;
  justify-content: space-between;
  background-color: #444;
  color: white;
  padding: 10px;
  z-index: 10;
}

.book-list-header {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr;
  gap: 15px;
  padding: 10px;
  border-bottom: 1px solid #666;
}

.book-list-footer {
  border-top: 1px solid #666;
}

.book-list-body {
  max-height: 400px;
  overflow-y: auto;
}

.book-list-row {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr;
  gap: 15px;
  padding: 10px;
  border-bottom: 1px solid #666;
}

.row-item {
  display: flex;
  align-items: center;
}
.category-filter {
  display: flex;
  justify-content: space-between;
}

.filter-grid {
  display: flex;
  flex-wrap: wrap;
}

.filter-item {
  width: 50%; /* This makes two items per row */
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adds spacing between rows */
}

@media screen and (max-width: 768px) {
  .book-list-row {
    grid-template-columns: 1fr 2fr 2fr 1fr;
  }

  .category-filter, .summary {
    display: flex;
    flex-direction: column;
  }

  .summary-item {
    margin-bottom: 10px;
  }
}

.save-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
}

.save-button:hover {
  background-color: #0056b3;
}

.actions {
  display: flex;
  gap: 15px;
  align-items: center;
}

h2, .summary-item, .header-item, .row-item, label {
  color: white;
}
