/* General Styles */
  
  /* h1, h2, h3, h4 {
    margin: 20px 0;
  } */
  
  section {
    padding: 50px 20px;
    text-align: center;
  }
  
  button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Hero Section */
  .hero {
    color: white;
    text-align: center;
    padding: 100px 20px;
  }
  
  .hero-content h1 {
    font-size: 3rem;
  }
  
  .hero-content p {
    font-size: 1.5rem;
  }

  .hero-content h1 {
    margin-top: 20px;
  }
  
  /* Our Mission Section */
  .our-mission {
    background-color: #f9f9f9;
  }
  .our-mission h2 {
    color: black;
    font-size: 30px;
    font-weight: 600;
  }
  
  .icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .icon {
    text-align: center;
  }
  
  .icon p {
    margin-top: 10px;
  }
  
  /* Our Team Section */
  .our-team {
    background-color: #ffffff;
  }
  
  .team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .team-card {
    text-align: center;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
  }
  
  .team-card img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
  
  /* Our Story Section */
  .our-story {
    background-color: #f4f4f4;
  }

  .our-story h2 {
    color: black;
    font-size: 30px;
    font-weight: 600;
  }
  
  .timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .milestone {
    margin: 20px 0;
  }
  
  /* Call to Action Section */
  .call-to-action {
    background-color: #00725d;
    color: white;
  }
  
  .call-to-action button {
    margin-top: 20px;
    background-color: white;
    color: #007bff;
    font-size: 30px;
    font-weight: 600;
  }
  
  /* Footer Section */
  .about-footer {
    background-color: #333;
    color: white;
    padding: 20px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .links a {
    color: white;
    margin-right: 20px;
    text-decoration: none;
  }
  
  .links a:hover {
    text-decoration: underline;
  }
  
  .social-media span {
    margin-right: 15px;
    font-size: 1.5rem;
  }
  