.signup-not-user-top-part{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    gap: 10px;
}
.signup-not-user-top-part img{

}
.signup-not-user-top-part-title{
    color: white;
    font-size: 50px;
}
.signup-form{
    position: relative;
    width: 812px;
    /* //border-radius: 17px; */
    margin-top: 30px;
    /* margin-left: 25%; */
    justify-self: center;
    margin-bottom: 20px;
    /* //background-color: white; */
    display: flex;
    flex-direction: row;
}
.signup-form-left-part{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    width: 820px;
    background-color: white;
    border-bottom-left-radius: 17px;
    border-top-left-radius: 17px;
}
.signup-form-left-part-signup-title{
    font-size: 40px;
    margin-bottom: 10px;
}
.signup-form-left-part form{
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 4px;
    align-items: center;
    /* //margin-left: 20%; */
}
.signup-form-left-part-1st-row{
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.signup-form-left-part-input{
    width: 297.5px;
}
.signup-form-left-part-phoneinput{
    width: 297.5px;
}
/* .login-form-left-part form label{
    display: flex;
    flex-direction: column;
} */
/* .signup-form-left-part form input{
    outline: 0;
    border-width: 0 0 2px;
    border-color: black
}
.signup-form-left-part form input:focus{
    //border-color: green;
    outline: none;
    border-width: 0 0 2px;
} */
/* .login-form-left-part form submit{
    cursor: pointer;
    background-color: #12B48D;
    padding: 10px;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
    color: white;
} */
.signup-form-left-part-button{
    cursor: pointer;
    background-color: #12B48D;
    padding: 2px;
    width: 191px;
    align-items: center;
    height: 37px;
    /* //margin-top: 35px; */
    font-size: 20px;
    font-weight: 500;
    color: white;
    text-align: center;
    align-self: center;
    /* //vertical-align: middle; */
}
.signup-form-left-part-bottom-part{
    margin-top: 40px;
}
.signup-form-left-part-bottom-part span{
    color: #12B48D;
    cursor: pointer;
}

.signup-form-right-part{
    display: flex;
    /* //align-items: flex-end; */
    width: 310px;
    /* //border-radius: 17px; */
    background-color: #24BA84;
    border-bottom-right-radius: 17px;
    border-top-right-radius: 17px;
    /* //border-width: 2px;
    //border-color: black;
    //z-index: 99; */
}
.signup-form-right-part img{
    width: 192px;
    height: 190px;
    align-self: center;
    margin-left: 5%;
}
.signup-with-google{
    width: 60px;
}
.signup-lower-part{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
}
.signup-lower-part span{
    color: blue;
    cursor: pointer;
}
.signup-show-password{
    display: flex;
    flex-direction: row;
    width: 297.5px;
    justify-content: center;
}
.signup-form-left-part-password{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.password-hints{
    color: red;
}
.recaptcha-container {
    display: flex; /* Ensure proper alignment */
    justify-content: center; /* Center align the reCAPTCHA */
    width: 100%; /* Take the full width of the container */
    max-width: 300px; /* Match the width of other inputs */
    margin: 0 auto 15px auto; /* Center horizontally and add bottom spacing */
}
@media screen and (max-width: 1024px){
    .signup-form{
        width: 612px;
        /* margin-left: 15%; */
    }
    .signup-form-left-part{
        /* //padding-top: 70px; */
        width: 620px;
    }
    .signup-form-left-part form{
        width: 300px;
       
        /* //margin-left: 20%; */
    }
    .signup-form-left-part-input{
    }
    .signup-form-left-part-phoneinput{
        width: 90%;
    }

}
@media screen and (max-width: 750px){
    .signup-form{
        width: 480px;
        margin-bottom: 80px;
    }
    .signup-form-left-part{
        /* //padding-top: 70px; */
        width: 420px;
        gap: 20px;
    }
    .signup-form-left-part form{
        width: 240px;
        /* //margin-left: 20%; */
        
    }
    .signup-form-left-part-input{
        width: 200px;
    }
    .signup-form-right-part img{
        width: 152px;
        height: 150px;
    }
    .signup-form-left-part submit{
        width: 101px;
        margin-left: 50%;
    }

}
@media screen and (max-width: 450px){
    .signup-form{
        /* //padding-bottom: 50px;
        //flex-direction: column; */
        width: 280px;
        /* margin-left: 10%; */
        margin-bottom: 250px;
    }

    .signup-not-user-top-part img{
        width: 20%;
    }
    .signup-not-user-top-part-title{
        font-size: 30px;
    }
    
    .signup-form-left-part{
        width: 290px;
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
        padding-top: 5px;
        /* //margin-bottom: 150px; */
    }
    .signup-form-left-part-signup-title{
        margin-bottom: 5px;
    }
    .signup-form-left-part form{
        width: 212px;
        /* //margin-left: 20%; */
    }
    .signup-form-left-part-input{
        width: 200px;
    }
    .signup-form-left-part-phoneinput{
        width: 90%;
    }
    .signup-form-right-part{
        display: none;
    }

    .password-hints{
        margin-left: 50px;
    }
    .g-recaptcha {
        transform: scale(0.85); /* Adjust the scale */
        transform-origin: center; /* Maintain proper alignment when scaling */
    }

}