/* General Navbar Styles */
.navbar-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: #24BA84;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 0 20px;
  transition: background-color 0.3s ease-in-out;
}

.navbar-logo-and-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar-logo {
  width: 50px;
  margin-right: 10px;
}

.navbar-title {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

/* Navbar Menu Styles */
.nav-menu {
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-links {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.7rem 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-links:hover {
  background-color: #01959a;
  color: white;
  border-radius: 4px;
}

.button {
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white;
  background-color: #01959a;
}

.button:hover {
  background-color: white;
  color: black;
}

/* Mobile Menu Styles */
.menu-icons {
  display: none;
}

.nav-menu-active {
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: white;
  width: 100%;
  height: auto;
  position: absolute;
  top: 80px;
  left: 0;
  opacity: 1;
  z-index: 999;
  transition: all 0.3s ease;
  padding: 10px;
}

.nav-links-mobile {
  display: none;
}

.nav-links-mobile.active {
  display: block;
  text-align: center;
  padding: 1rem;
  margin: auto;
  border-radius: 4px;
  width: 80%;
  background: #01959a;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
}

.navbar-profile-icons {
  cursor: pointer;
  font-size: 2rem;
  color: white;
  position: relative;
}

.navbar-profile-menu-list {
  position: absolute;
  background-color: #24BA84;
  width: 200px;
  display: flex;
  flex-direction: column;
  top: 80px;
  right: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.navbar-profile-menu-list li {
  color: white;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.navbar-profile-menu-list li:last-child {
  border-bottom: none;
}

.navbar-profile-menu-list li:hover {
  background-color: #01959a;
}
.language-switcher-profile-icon{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.navbar-profile-menu-logout{
  background-color: red;
}

/* Responsive Styles */
@media screen and (max-width: 1250px) {
  .navbar-logo {
    width: 40px;
  }
  .navbar-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 950px) {
  .nav-menu {
    display: none;
  }
  .nav-menu-active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    background-color: white;
    width: 100%;
    padding: 10px;
  }
  .nav-links {
    display: block;
    width: 100%;
    font-size: 1.5rem;
    padding: 1rem;
    text-align: center;
    background-color: #24BA84;
  }
  .menu-icons {
    display: block;
    cursor: pointer;
    font-size: 2rem;
    color: white;
  }
  .button {
    display: none;
  }
  .navbar-profile-icon-desktop {
    display: none;
  }
  .navbar-profile-icon-mobile {
    display: block;
  }
}

@media screen and (max-width: 850px) {
  .navbar-profile-menu-list {
    right: 10px;
    width: 180px;
  }
}

@media screen and (max-width: 576px) {
  .nav-links {
    background-color: #24BA84;
    font-size: 1rem;
    padding: 0.5rem 0.7rem;
  }
  .nav-links-mobile {
    width: 200px;
    height: 50px;
    font-size: 1.1rem;
  }
  .navbar-profile-menu-list {
    width: 160px;
  }
}

@media screen and (max-width: 380px) {
  .nav-links {
    font-size: 0.9rem;
    padding: 0.5rem 0.5rem;
  }
  .nav-links-mobile {
    width: 180px;
    height: 40px;
    font-size: 1rem;
  }
  .navbar-profile-menu-list {
    width: 140px;
  }
}
