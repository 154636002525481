/* Language Switcher Styles */
.language-switcher {
    position: relative;
  }
  
  .language-switcher-button {
    cursor: pointer;
    font-size: 1.5rem;
    color: white;
  }
  
  .language-dropdown {
    position: absolute;
    background-color: white;
    width: 120px;
    top: 100%;
    right: 0;
    z-index: 10000; /* Ensures it appears above other elements */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: none; /* Hidden by default */
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateY(10px);
  }
  
  .language-dropdown.show {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
  
  .language-dropdown-item {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .language-dropdown-item:last-child {
    border-bottom: none;
  }
  
  .language-dropdown-item:hover {
    background-color: #f0f0f0;
  }
  