.monthly-plan-middle-part {
    margin-top: 100px;
    /* //width: 90%; */
    height: auto;
    /* display: flex;
    flex-direction: column; */
    gap: 15px;
    /* //margin-left: 30%; */
}


.monthly-plan-middle-part-1st-row {
    display: flex;
    flex-direction: column;
    /* //margin-left: 50%; */
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.monthly-plan-middle-part-1st-row-title {
    color: white;
    font-size: 30px;
    margin-bottom: 20px;
}

.monthly-plan-middle-part-1st-row-lowerpart {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.monthly-plan-middle-part-1st-row-datepicker {
    color: black;
    text-align: center;
}

.monthly-plan-middle-part-1st-row-lowerpart button {
    background-color: #00FFC2;
    color: black;
}

.monthly-plan-middle-part-1st-row-lowerpart div2 div22 {
    color: white;
    margin-right: 5px;
}

.monthly-plan-middle-part-1st-row-lowerpart div2 input {
    width: 50px;
    border-width: 1px;
    text-align: center;
    padding: 5px;
    color: black;
}

.monthly-plan-data-part {
    margin-top: 30px;
    /* margin-left: 10%; */
}

.monthly-plan-data-part-1st-row {
    padding: 5px;
}

.monthly-plan-data-part-topic {
    /* //width: 25%; */
    height: 50px;
    background-color: #65FFBE;
    /* //margin-right: 25px; */
    font-weight: 500;
    /* //padding: 10px; */
    text-align: center;
    align-content: center;
}

.monthly-plan-data-part-literature {
    /* //width: 25%; */
    height: 50px;
    background-color: #65FFBE;
    /* //margin-right: 15px; */
    font-weight: 500;
    /* //padding: 10px; */
    text-align: center;
    justify-content: center;
}

.monthly-plan-data-part-days {
    color: white;
    /* //margin-right: 10px; */
    text-align: right;
    padding: 10px;
    /* //align-items: flex-end; */


}

.monthly-plan-data-part-number {
    width: 70px;
    border-width: 1px;
    /* //text-align: center; */
    align-content: center;
    /* //padding: 10px; */
    /* margin-right: 20px; */
    /* //align-items: flex-start; */
}

.monthly-plan-data-part-miss {
    width: 25%;
    height: 163px;
    background-color: #65FFBE;
    margin-right: 25px;
    font-weight: 500;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.monthly-plan-data-part-miss-1st-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;
    margin-right: 50px;
}

.monthly-plan-data-part-miss-1st-column-1st-row div1 {
    margin-right: 20px;
}

.monthly-plan-data-part-miss-1st-column-1st-row input {
    height: 47px;
    width: 50px;
}

.monthly-plan-data-part-miss-1st-column-2nd-row div1 {
    margin-right: 24px;
}

.monthly-plan-data-part-miss-1st-column-2nd-row input {
    height: 47px;
    width: 50px;
}

.monthly-plan-data-part-miss-1st-column-3rd-row div1 {
    margin-right: 24px;
}

.monthly-plan-data-part-miss-1st-column-3rd-row input {
    height: 47px;
    width: 50px;
}


.monthly-plan-data-part-miss-2nd-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;
    margin-right: 50px;
}

.monthly-plan-data-part-miss-2nd-column-1st-row div1 {
    margin-right: 20px;
}

.monthly-plan-data-part-miss-2nd-column-1st-row input {
    height: 47px;
    width: 50px;
}

.monthly-plan-data-part-miss-2nd-column-2nd-row div1 {
    margin-right: 98px;
}

.monthly-plan-data-part-miss-2nd-column-2nd-row input {
    height: 47px;
    width: 50px;
}

.monthly-plan-data-part-miss-2nd-column-3rd-row div1 {
    margin-right: 37px;
}

.monthly-plan-data-part-miss-2nd-column-3rd-row input {
    height: 47px;
    width: 50px;
}

.monthly-plan-data-part-comment {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-top: 30px;
}

.monthly-plan-data-part-comment div1 {
    color: white;
    text-align: center;
    align-self: center;

}

.monthly-plan-data-part-comment textarea {
    width: 500px;
    height: 120px;

}

.monthly-plan-middle-part-bottom-row {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: center;
}

.monthly-plan-middle-part-add-new {
    background-color: #65FFBE;
    width: 20%;
    align-items: center;
    text-align: center;
    padding: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.monthly-plan-change-saved {
    margin-left: 40%;
    background-color: #00FFC2;
    width: 10%;
    padding: 10px;
    text-align: center;
    margin-top: 40px;
    border-radius: 30px;
    cursor: pointer;
    font-weight: 500;
}

.monthly-plan-middle-part-1st-row-datepicker-part {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

@media screen and (max-width: 1050px) {
    .monthly-plan-data-part-topic {
        width: 20%;
        height: 70px;
        margin-right: 15px;
        font-weight: 500;
        font-size: 20px;
        padding: 5px;
    }

    .monthly-plan-data-part-literature {
        width: 20%;
        height: 70px;
        margin-right: 15px;
        font-weight: 500;
        font-size: 20px;
        /* //padding: 10px; */
    }

    .monthly-plan-data-part-days {
        margin-right: 5px;

    }

    .monthly-plan-data-part-Number {
        width: 40px;
        height: auto;
        text-align: center;
        margin-right: 5px;
    }
}

@media screen and (max-width: 560px) {
    .monthly-plan-middle-part-1st-row-lowerpart {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 450px) {
    .monthly-plan-middle-part-1st-row {
        gap: 5px;
    }

    .monthly-plan-middle-part-1st-row div1 {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .monthly-plan-change-saved {
        width: 30%;
    }

    .monthly-plan-data-part-number {
        width: 100px;
    }

    .monthly-plan-middle-part-1st-row-datepicker {
        width: 100px;
    }
}

@media screen and (max-width: 350px) {
    .monthly-plan-middle-part-1st-row {
        font-size: 15px;
    }

    .monthly-plan-data-part-number {
        width: 100px;
    }

    .monthly-plan-middle-part-add-new {
        width: 60%;

    }
}