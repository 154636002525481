/* General Styles */
/* body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
} */

.contact-hero, .contact-info, .contact-form-section h1, h2, h3 {
  margin: 20px 0;
}

section {
  padding: 50px 20px;
  text-align: center;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

/* Hero Section */
.contact-hero {
  /* background: url('/path/to/hero-image.jpg') center/cover no-repeat; */
  color: white;
  text-align: center;
  padding: 100px 20px;
}

.contact-hero-content h1 {
  font-size: 3rem;
}

.contact-hero-content p {
  font-size: 1.5rem;
}


.contact-form {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.contact-form-group {
  margin-bottom: 15px;
}

.contact-form-group label {
  display: block;
  margin-bottom: 5px;
}

.contact-form-group input, .contact-form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Contact Information Section */
.contact-info {
  background-color: #ffffff;
  padding: 50px 20px;
  text-align: left;
}

.contact-info .info {
  max-width: 600px;
  margin: 0 auto;
}

.contact-info div {
  margin-bottom: 20px;
}

.contact-social-media span {
  font-size: 1.5rem;
  margin-right: 15px;
}

/* Map Section */
.contact-map {
  background-color: #f4f4f4;
}

.contact-map-container {
  max-width: 100%;
  overflow: hidden;
}

/* Footer Section */
.contact-footer {
  background-color: #333;
  color: white;
  padding: 20px;
}

.contact-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-links a {
  color: white;
  margin-right: 20px;
  text-decoration: none;
}

.contact-links a:hover {
  text-decoration: underline;
}

.contact-social-media span {
  margin-right: 15px;
  font-size: 1.5rem;
}
