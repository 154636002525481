.edit-button,
.save-button {
    background-color: white;
    padding: 5px;
    height: 40px;
    margin-top: 10px;
    cursor: pointer;
    color: black;
}

.edit-button:hover,
.save-button:hover {
    background-color: #65FFBE;
}

.target-list-middle-part {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
}

.target-list-middle-part-1st-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.target-list-middle-part-1st-row-title {
    color: white;
    font-size: 30px;
    margin-bottom: 20px;
}

.target-list-middle-part-1st-row-lowerpart {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.target-list-middle-part-1st-row-datepicker {
    color: black;
    text-align: center;
}

.target-list-middle-part-1st-row-lowerpart button {
    background-color: #00FFC2;
    color: black;
}

.target-list-middle-part-grid {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 50%;
}

.target-list-middle-part-grid-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.target-list-middle-part-grid-header1 {
    /* display: flex; */
    /* flex-direction: column; */
    background-color: #65FFBE;
    margin-top: 10px;
    font-weight: 600;
    font-size: 20;
    text-align: center;
    vertical-align: middle;
    align-content: center;
    /* padding: 5px; */
    width: 20%;
}

.target-list-middle-part-grid-header2 {
    background-color: white;
    margin-top: 10px;
    font-weight: 600;
    font-size: 20;
    text-align: center;
    vertical-align: middle;
    align-content: center;
    /* //padding: 5px; */
    width: 20%;
}

.target-list-middle-part-grid-header3 {
    /* //background-color: white; */
    margin-top: 10px;
    font-weight: 600;
    font-size: 20;
    text-align: center;
    vertical-align: middle;
    align-content: center;
    /* //padding: 5px; */
    width: 20%;
}

.target-list-middle-part-grid-header-input {
    /* //height: 40px; */
    /* //margin-top: 10px; */
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    vertical-align: middle;
    align-content: center;
    /* //padding: 5px; */
    width: 100%;
}

.target-list-middle-part-grid-icon {
    background-color: white;
    padding: 10px;
    height: 40px;
    margin-top: 10px;
    cursor: pointer;
}

.target-list-middle-part-grid-add-new {
    background-color: #65FFBE;
    width: 40%;
    align-items: center;
    height: 40px;
    text-align: center;
    padding: 5px;
    margin-top: 10px;
    cursor: pointer;
}

@media screen and (max-width: 1300px) {
    .target-list-middle-part {
        /* width: 100%; */
    }

    .target-list-middle-part-1st-row {
        /* gap: 10px; */
    }
}

@media screen and (max-width: 960px) {
    .target-list-middle-part {
        width: 100%;
    }

    .target-list-middle-part-1st-row {
        /* margin-left: 5%; */
        /* gap: 10px; */
    }

    .target-list-middle-part-grid-header1 {
        width: 20%;
    }

    .target-list-middle-part-grid-header2 {
        font-weight: 500;
        font-size: 15px;
        /* //padding: 5px; */
        width: 20%;
    }

    .target-list-middle-part-grid {
        width: 70%;
    }

}

@media screen and (max-width: 550px) {
    .target-list-middle-part {
        /* width: 100%; */
    }

    .target-list-middle-part-1st-row {
        /* margin-left: 20%; */
        /* gap: 10px; */
    }

    .target-list-middle-part-grid-row {
        gap: 2px;
        /* margin-left: 10%; */
    }

    .target-list-middle-part-grid {
        width: 90%;
    }

    .target-list-middle-part-grid-header1 {
        font-weight: 500;
        font-size: 10px;
    }

    .target-list-middle-part-grid-header2 {
        font-weight: 500;
        font-size: 10px;
    }

    .target-list-middle-part-grid-header-input {
        font-weight: 500;
        font-size: 10px;
    }
}

@media screen and (max-width: 360px) {

    .target-list-middle-part-1st-row-datepicker {
        width: 80px;
    }

    .target-list-middle-part-grid {
        width: 95%;
    }

    .target-list-middle-part-grid-header1 {
        font-size: 10px;
    }

    .target-list-middle-part-grid-header2 {
        font-size: 10px;
        text-align: center;
        align-items: center;
    }
}