.report-summary-middle-part{
  margin-top: 100px;
}
.report-summary-middle-part-1st-row{
  display: flex;
  flex-direction: column;
  //margin-left: 50%;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.report-summary-middle-part-1st-row-title{
  color: white;
  font-size: 30px;
  margin-bottom: 20px;
}
.report-summary-middle-part-1st-row-lowerpart{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.report-summary-middle-part-1st-row-datepicker{
  color: black;
  text-align: center;
}
.report-summary-middle-part-1st-row-lowerpart button{
  background-color: #00FFC2;
  color: black;
}
.report-summary-middle-part-1st-row-total-days{
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.report-summary-middle-part-1st-row-total-days-title{
  color: white;
  font-size: 20px;
}
.report-summary-middle-part-1st-row-total-days div2 div22{
  color: white;
  margin-right: 5px;
}
.report-summary-middle-part-1st-row-lowerpart-total-days{
  border-width: 1px;
  text-align: center;
  padding: 15px;
  color: white;
}
.report-summary-data-part{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 10%;
}
.report-summary-data-part-1st-row{
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.report-summary-data-part-topic{
  //width: 15%;
  height: 50px;
  background-color: #65FFBE;
  //margin-right: 10px;
  font-weight: 500;
  //padding: 10px;
  text-align: center;
  align-content: center;
}
.report-summary-data-part-literature{
  //width: 15%;
  height: 50px;
  background-color: #65FFBE;
  //margin-right: 10px;
  font-weight: 500;
  //padding: 10px;
  text-align: center;
  align-content: center;
}
.report-summary-data-part-days{
  color: white;
  //margin-right: 10px;
  text-align: center;
  padding: 10px;
  //align-items: flex-end;

}
.report-summary-data-part-Number{
  //width: 50px;
  border-width: 1px;
  color: white;
  text-align: center;
  padding: 10px;
  //margin-right: 20px;
  //align-items: flex-start;
}
.report-summary-data-part-miss-row{
  flex-direction: row;
  padding: 5px;
  
}
.report-summary-data-part-miss{
  //width: 25%;
  height: 163px;
  background-color: #65FFBE;
  //margin-right: 25px;
  font-weight: 500;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.report-summary-data-part-miss-1st-column{
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: white;
  //margin-right: 50px;
}
.report-summary-data-part-miss-1st-column-1st-row div1{
  margin-right: 20px;
}
.report-summary-data-part-miss-1st-column-1st-row input{
  height: 47px;
  width: 50px;
}
.report-summary-data-part-miss-1st-column-2nd-row div1{
  margin-right: 24px;
}
.report-summary-data-part-miss-1st-column-2nd-row input{
  height: 47px;
  width: 50px;
}
.report-summary-data-part-miss-1st-column-3rd-row div1{
  margin-right: 24px;
}
.report-summary-data-part-miss-1st-column-3rd-row input{
  height: 47px;
  width: 50px;
}


.report-summary-data-part-miss-2nd-column{
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: white;
  margin-right: 50px;
}
.report-summary-data-part-miss-2nd-column-1st-row div1{
  margin-right: 20px;
}
.report-summary-data-part-miss-2nd-column-1st-row input{
  height: 47px;
  width: 50px;
}
.report-summary-data-part-miss-2nd-column-2nd-row div1{
  margin-right: 98px;
}
.report-summary-data-part-miss-2nd-column-2nd-row input{
  height: 47px;
  width: 50px;
}
.report-summary-data-part-miss-2nd-column-3rd-row div1{
  margin-right: 37px;
}
.report-summary-data-part-miss-2nd-column-3rd-row input{
  height: 47px;
  width: 50px;
  //margin-right: 10px;
}
.report-summary-data-part-comment{
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 30px;
  align-self: center;
}
.report-summary-data-part-comment div1{
  color: white;
  text-align: center;
  align-self: center;

}
.report-summary-data-part-comment textarea{
  width: 500px;
  height: 120px;

}

.report-summary-middle-part-bottom-row{
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: center;
}
.report-summary-middle-part-add-new{
  background-color: #65FFBE;
  width: 25%;
  align-content: center;
  text-align: center;
  padding: 5px;
  margin-top: 10px;
  cursor: pointer;
}
.report-summary-change-saved{
  /* margin-left: 40%; */
  background-color: #00FFC2;
  width: 10%;
  padding: 10px;
  text-align: center;
  margin-top: 40px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  align-self: center;
}
.report-summary-upper-arrow{
  width: 54px;
  height: 47px;
  background-color: #D9D9D9;
  color: green;
  align-self: center;
  align-content: center;
  text-align: center;
  //font-weight: bold;
}
.report-summary-lower-arrow{
  width: 54px;
  height: 47px;
  background-color: #D9D9D9;
  color: red;
  align-self: center;
  align-content: center;
  text-align: center;
}

@media screen and (max-width: 1050px){
  .report-summary-data-part{
      margin-left: 5%;
  }
  .report-summary-data-part-topic{
      width: 20%;
      height: 70px;
      margin-right: 15px;
      font-weight: 500;
      font-size: 20px;
      padding: 5px;
  }
  .report-summary-data-part-literature{
      width: 20%;
      height: 70px;
      margin-right: 15px;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 5px;
      //padding: 10px;
  }
  .report-summary-data-part-days{
      margin-right: 5px;
  
  }
  .report-summary-data-part-Number{
      width: 40px;
      height: auto;
      text-align: center;
      margin-right: 5px;
  }
  .report-summary-data-part-comment textarea{
      width: 300px;
      height: 120px;
  
  }
}

@media screen and (max-width: 450px){
  .report-summary-middle-part-1st-row{
      gap: 5px;
  }
  .report-summary-middle-part-1st-row-lowerpart{
      flex-direction: row;
      gap: 10px;
  }
  .report-summary-middle-part-1st-row-datepicker{
      width: 100px;
  }
  .report-summary-middle-part-1st-row-lowerpart-total-days{
      height: auto;
      width: 20px;
  }
  .report-summary-change-saved{
      width: 30%;
  }
  .report-summary-data-part-miss{
      //width: 25%;
      height: 63px;
  }
  .report-summary-data-part-comment textarea{
      width: 250px;
      height: 120px;
  
  }
  .report-summary-data-part-miss-row{
      gap: 10px;
      
  }
}
@media screen and (max-width: 350px){
  .report-summary-middle-part-1st-row{
      font-size: 10px;
  }
  .report-summary-data-part-comment textarea{
      width: 170px;
  
  }
}
