/* General Styles */
/* body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.6;
  } */

  .usage-section-container{
    margin-top: 100px;
  }
  
  .usage-section h1,h2 {
    margin: 20px 0;
    color: white;
  }
  
  .usage-section p {
    margin: 10px 0;
    color: white;

  }
  
  .usage-section {
    padding: 30px 20px;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .usage-section a {
    color: #007bff;
    text-decoration: none;
  }
  
  .usage-section a:hover {
    text-decoration: underline;
  }
  